.business_card {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 340px;
  background: #f6f5f8;
}

.article {
  display: flex;
  align-items: center;
  width: 340px;
  text-align: center;
  font-family: Lora;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 117%;
  padding: 10px;
  color: #484848;
}

.article_text {
  flex: 1;
}

.cancel_button {
  cursor: pointer;
}

.photo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 340px;
  color: #484848;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 117%;
  background: #eaeaeb;
  margin-bottom: 12px;
}

.photo img {
  object-fit: cover;
}

.info_items {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.item {
  padding-bottom: 20px;
}

.label {
  color: #a0a0a0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 10px;
}

.item_text {
  color: #484848;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-break: break-all;
}

.buttons {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
}

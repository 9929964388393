@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Lora:wght@400;500;600&display=swap");

$inter: "Inter", sans-serif;
$Lora: "Lora", serif;
$basicColor: #EDEDED;
$labelColor: #484848;

.button {
    cursor: pointer;
    background: $basicColor;
    border: none;
    border-radius: 10px;
    width: 188px;
    height: 48px;
    color: $labelColor;
    text-align: center;
    font-family: $Lora;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Lora&display=swap");

$theader: #f0eff2;

$inter: "Inter", sans-serif;

.menu_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 11px;
  padding-top: 12px;

  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(151, 154, 168, 0.3);

  color: var(--text, #353535);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.changePassword,
.logout {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding-bottom: 12px;
}

.icon {
  height: 24px;
  width: 24px;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Lora&display=swap");

$theader: #f0eff2;

$inter: "Inter", sans-serif;

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  left: 120px;
  width: 1202px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 0 auto;
  padding-top: 12px;
}

.noDataCap {
  display: flex;
  justify-content: center;
  font-family: $inter;
  font-size: 24px;
  font-weight: 600;
  color: #484848;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Lora:wght@400;500;600&display=swap");

$inter: "Inter", sans-serif;
$Lora: "Lora", serif;
$basicColor: #484848;
$labelColor: #a0a0a0;

.button {
  background: $basicColor;
  border: none;
  border-radius: 10px;
  width: 188px;
  height: 48px;
  color: #fff;
  text-align: center;
  font-family: $Lora;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.div_button_create {
  cursor: pointer;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Lora:wght@400;500;600&display=swap");

$inter: "Inter", sans-serif;
$Lora: "Lora", serif;
$basicColor: #484848;
$labelColor: #A0A0A0;

.form {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  a {
    text-decoration: none;
  }
  .loginWrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  .mainBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 55%;
    width: 48%;
    border-radius: 20px;
    background-color: #f6f5f8;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }
  
  .logoBlock {
    display: flex;
    padding-top: 28px;
  }
  
  .logo {
    width: 78px;
    height: 78px;
  }
  
  .wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .logintext {
    color: $basicColor;
    padding-top: 12px;
    font-family: $Lora;
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
  }
  
  .secondtext {
    width: 300px;
    padding-top: 29px;
    color: $basicColor;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%;
  }
  
  
  .inputItems {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .email {
    display: flex;
    flex-direction: column;
  }
  
  .password {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
  
  
  .btnWrapper {
    padding-top: 39px;
  }
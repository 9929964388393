@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Lora&display=swap");

$theader: #f0eff2;

$inter: "Inter", sans-serif;

.business_card {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 340px;
  background: #F0EFF2;
}

.icon_wrapper {
  width: 65px;
  height: 65px;
  padding-bottom: 9px;
}

.cap_text {
  color: #A0A0A0;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

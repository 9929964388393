@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Lora:wght@400;500;600&display=swap");

$inter: "Inter", sans-serif;
$Lora: "Lora", serif;
$basicColor: #484848;
$labelColor: #a0a0a0;

.input {
  font-family: $inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 20px;
  width: 130px;
  height: 38px;
  border-radius: 10px;
  border: none;
  background: #e9e8eb;
  text-overflow: ellipsis;
}

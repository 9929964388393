@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Lora:wght@400;500;600&display=swap");

$inter: "Inter", sans-serif;
$Lora: "Lora", serif;
$basicColor: #484848;
$labelColor: #A0A0A0;


.input {
  font-family: $inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 20px;
  width: 440px;
  height: 48px;
  border-radius: 10px;
  border: none;
  background: #fff;
  text-overflow: ellipsis;
}

.label {
  position: relative;
  display: flex;
  flex-direction: column;
}

.labeldiv {
    color: $labelColor;
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 12px;
    padding-bottom: 4px;
}

.eye {
  cursor: pointer;
  position: absolute;
  left: 420px;
  top: 12px;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Lora:wght@400;500;600&display=swap");

$inter: "Inter", sans-serif;
$Lora: "Lora", serif;
$basicColor: #484848;
$labelColor: #a0a0a0;

.buttonForgot {
  cursor: pointer;
  padding-top: 24px;
  color: #484848;
  font-family: $inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

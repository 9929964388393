@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Lora:wght@600&display=swap');

$grayIsActive: #484848;
$underborder: #ededed;

$menuFontSize: 20px;

$Lora: "Lora", serif;

.header {
  display: flex;
  width: 100%;
  height: 70px;
  font-family: $Lora;
  font-weight: 600;
  font-style: normal;
  border-bottom: 1px solid;
  border-color: $underborder;
  margin: 0 auto;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  width: 1202px;
}

.links {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.item {
  margin-right: 60px;
  font-size: 20px;
  line-height: 117%;
  transition: 0.5s linear;
}

.logo_item {
  margin-right: 60px;
  font-size: 20px;
  line-height: 117%;
  transition: 0.5s linear;
}

.item a{
  font-size: $menuFontSize;
  color: $grayIsActive;
  opacity: 30%;
  text-decoration: none;
}

.item.active a {
  font-size: $menuFontSize;
  color: $grayIsActive;
  opacity: 100%;
  text-decoration: none;
}

.profile {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: $grayIsActive;
  border-radius: 50%;
}

.showMenuWrapper {
  position: absolute;
  top: 60px;
  right: 0px;
  margin: 0;
  width: 230px;
  height: 96px;
}